<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-card
          :class="{ 'card-success': cadastro, 'card-warn': !cadastro }"
          style="min-height: 60vh"
        >
          <h4 v-if="!cadastro" slot="header" class="card-title text-center">
            Edição de Gestor
          </h4>
          <h4 v-else slot="header" class="card-title text-center">
            Cadastro de Gestor
          </h4>
          <div class="clearfix" />
          <div>
            <v-form
              id="form"
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              class="pa-3"
              lazy-validation
              @submit.prevent="updateGestor"
            >
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Dados Pessoais
                  </v-card-title>
                  <v-row v-if="!loading" class="pa-3">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.nome"
                        label="Nome *"
                        name="nome"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.email"
                        name="email"
                        label="Email *"
                        :rules="emailRules"
                        :disabled="edit"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.cpf"
                        v-mask="maskCpf"
                        name="cpf"
                        label="CPF *"
                        :rules="cpfRules"
                        :disabled="edit"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="form.sexo"
                        :items="sexos"
                        :rules="requiredRules"
                        item-text="text"
                        name="sexo"
                        item-value="text"
                        label="Sexo *"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="form.CodAgroindustria"
                        :items="agroindustrias"
                        :loading="loadingAgroindustrias"
                        :rules="requiredRules"
                        item-text="text"
                        item-value="value"
                        name="agroindustria"
                        label="Agroindustria *"
                        multiple
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="form.estado"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Estado *"
                        name="estado"
                        :items="estado"
                        :rules="requiredRules"
                        :loading="loadingEstados"
                        :disabled="loadingEstados"
                        item-text="Nome"
                        item-value="CodEstado"
                        required
                        @change="changeCidade"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="form.CodCidade"
                        name="cidade"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Cidade *"
                        :loading="loadingCidades"
                        :items="cidade"
                        :rules="requiredRules"
                        item-text="Nome"
                        item-value="CodCidade"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.logradouro"
                        label="Logradouro"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field v-model="form.numero" label="Numero" />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="form.bairro" label="Bairro" />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.complemento"
                        label="Complemento"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.cep"
                        v-mask="maskCep"
                        type="text"
                        label="CEP"
                        name="cep"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.telCel"
                        v-mask="maskTelCel"
                        :rules="telRules"
                        label="Telefone Celular *"
                        name="telefone"
                        type="tel"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Permissões
                  </v-card-title>
                  <v-row class="pa-3">
                    <v-col cols="6" sm="4">
                      <v-autocomplete
                        v-model="form.permissions"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Relatórios "
                        name="permissions"
                        :items="permissions"
                        :loading="loadingPermissions"
                        :disabled="loadingPermissions"
                        item-text="title"
                        item-value="report_id"
                        clearable
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>
              <v-btn
                type="submit"
                :disabled="salvando"
                :loading="salvando"
                :color="!$route.query.CodGestor ? 'success' : 'warning'"
                form="form"
              >
                {{ btnNome }}
              </v-btn>

              <router-link
                :disabled="salvando"
                :to="`/${$user.get().role}/gestores`"
              >
                <v-btn :disabled="salvando" color="error" class="ml-3">
                  Cancelar
                </v-btn>
              </router-link>
            </v-form>
          </div>
        </v-card>
      </template>
    </div>
  </v-card>
</template>
<script>
import { ENV_APP } from '../../../env'
import { _orderByName } from '../../utils/formatter'
import { validaCPF } from '../../utils/validacoes'

export default {
  data() {
    return {
      edit: false,
      agroindustrias: [],
      salvando: false,
      loading: true,
      loadingEstados: true,
      loadingCidades: false,
      loadingPermissions: true,
      loadingAgroindustrias: true,
      sexos: [{ text: 'Masculino' }, { text: 'Feminino' }],
      maskCpf: '###.###.###-##',
      maskTelCel: '(##) # ####-####',
      maskCep: '##.###-###',
      emailRules: [v => /.+@.+/.test(v) || 'E-mail inválido'],
      telRules: [
        v => !v || v.length === 0 || v.length >= 10 || '* Número inválido',
        v => !!v || '* Obrigatório',
      ],
      cpfRules: [
        v => !!v || '* Obrigatório',
        v => validaCPF(v) || '* CPF inválido',
      ],
      requiredRules: [v => !!v || '* Obrigatório'],
      valid: false,
      btnNome: !this.$route.query.CodGestor ? 'Salvar' : 'Salvar Alterações',
      form: {
        CodGestor: null, // código do gestor
        CodAgroindustria: [],
        permissions: [],
        nome: null,
        email: null,
        cpf: null,
        sexo: null,
        telCel: null,
        estado: null,
        CodCidade: null,
        bairro: null,
        logradouro: null,
        numero: null,
        complemento: null,
        cep: null,
      },
      estado: [],
      cidade: [],
      permissions: [],
      cadastro: this.$route.query.CodGestor === 0 ? 1 : 0,
    }
  },
  created() {
    if (this.$route.query.CodGestor) {
      this.form.CodGestor = this.$route.query.CodGestor
      this.edit = true
      this.formDados()
    } else {
      this.loadNewForm()
    }
  },
  methods: {
    loadNewForm() {
      this.loadAgroindustrias()
      this.loadEstado()
      this.loadPermissions()
      this.loading = false
    },
    updateGestor() {
      this.valid = this.$refs.form.validate()

      this.form.permissions = this.form.permissions
        ? this.form.permissions.map(permission => {
            return { report_id: permission }
          })
        : []

      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        return false
      } else {
        if (this.form.CodGestor) {
          this.salvando = true
          // ATUALIZAR DADOS GESTOR
          this.api.update
            .gestor(this.form.CodGestor, this.form)
            .then(response => {
              this.Swal.fire({
                title: 'Sucesso',
                text: response,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/gestores`,
                })
              })
            })
            .catch(() => {
              this.salvando = false
            })
        } else {
          this.salvando = true
          // CRIAR NOVO GESTOR
          this.api.save
            .gestor(this.form)
            .then(response => {
              this.Swal.fire({
                title: 'Sucesso',
                text: response,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/gestores`,
                })
              })
            })
            .catch(() => {
              this.salvando = false
            })
        }
      }
    },
    formDados() {
      this.api.get.gestor(this.form.CodGestor).then(data => {
        this.form.CodAgroindustria = data.usuario_agroindustria.map(
          item => item.CodAgroindustria,
        )
        this.form.nome = data.pessoa.pessoafisica.Nome
        this.form.email = data.pessoa.email
        this.form.cpf = data.pessoa.pessoafisica.Cpf
        this.form.sexo = data.pessoa.pessoafisica.sexo
        this.form.telCel = data.pessoa.TelCel
        this.form.estado = data.pessoa.endereco.cidade.CodEstado
        this.form.CodCidade = data.pessoa.endereco.cidade.CodCidade
        this.form.bairro = data.pessoa.endereco.Bairro
        this.form.logradouro = data.pessoa.endereco.Logradouro
        this.form.numero = data.pessoa.endereco.Numero
        this.form.complemento = data.pessoa.endereco.Complemento
        this.form.cep = data.pessoa.endereco.Cep
        this.form.permissions = data?.user?.permissions?.map(item =>
          parseInt(item.report_id),
        )
        this.loadAgroindustrias()
        this.loadEstado()
        this.loadCidade(this.form.estado)
        this.loadPermissions()
        this.loading = false
        this.valid = true
      })
    },
    loadEstado() {
      this.api.get.estados().then(data => {
        this.estado = data
        this.loadingEstados = false
      })
    },
    loadCidade(CodEstado) {
      this.loadingCidades = true
      this.api.get.cidades(CodEstado).then(data => {
        this.cidade = data
        this.loadingCidades = false
      })
    },
    changeCidade() {
      this.loadCidade(this.form.estado)
    },
    loadAgroindustrias() {
      this.api.get.agroindustrias().then(data => {
        this.agroindustrias = data
        this.agroindustrias = this.agroindustrias.filter(item => {
          return item.pessoa?.pessoajuridica?.NomeFantasia
        })
        this.agroindustrias = this.agroindustrias.map(item => {
          return {
            text: item.pessoa.pessoajuridica.NomeFantasia,
            value: item.CodAgroindustria,
          }
        })

        this.agroindustrias = _orderByName({
          data: this.agroindustrias,
          key: 'text',
        })

        if (this.agroindustrias.length) {
          this.agroindustrias.unshift({ text: '-- Selecione --', value: '' })
        }
        this.loadingAgroindustrias = false
      })
    },
    loadPermissions() {
      this.api.get.listReports({ projectId: ENV_APP.ProjectID }).then(data => {
        this.permissions = data
        this.loadingPermissions = false
      })
    },
  },
}
</script>
